import { AddBox, Delete, Edit, ExpandMore, Visibility } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { FormsContext } from "../../data/contexts/FormsContext";
import { ssQuestionsToSSQuestionsInput } from "../../data/dataConvertors";
import { Field, SsQuestion } from "../../data/generated/graphql";
import CenteredContent from "../../utils/CenteredContent";
import SsQuestionModal from "./SsQuestionsModal";
import SsQuestionsPreviewModal from "./SsQuestionsPreviewModal";

interface SwornStatementConfigProps {
  formId: string;
  ssQuestions?: SsQuestion[];
}

const SwornStatementConfig: FC<SwornStatementConfigProps> = ({ formId, ssQuestions }) => {
  const { t } = useTranslation("form");
  const { updateFormSsQuestions, forms } = useContext(FormsContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState<SsQuestion | undefined>(undefined);
  const [isDeletingQuestion, setDeletingQuestion] = useState<string | null>(null);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const visibleFields: Field[] = forms.find((f) => f.id === formId)?.fields || [];

  const handleOpenModal = (question?: SsQuestion): void => {
    setEditingQuestion(question);
    setModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setEditingQuestion(undefined);
    setModalOpen(false);
  };

  const handleSaveQuestion = async (updatedQuestion: SsQuestion): Promise<void> => {
    const updatedQuestions = ssQuestions ? [...ssQuestions] : [];
    const index = updatedQuestions.findIndex((q) => q.id === updatedQuestion.id);
    if (index !== -1) {
      updatedQuestions[index] = updatedQuestion;
    } else {
      updatedQuestions.push({ ...updatedQuestion, id: uuidv4() });
    }
    const result = await updateFormSsQuestions(formId, ssQuestionsToSSQuestionsInput(updatedQuestions));
    if (result) {
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
      setModalOpen(false);
    } else {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
      setModalOpen(false);
    }
  };

  const handleDeleteQuestion = async (id: string): Promise<void> => {
    setDeletingQuestion(id);
    const updatedQuestions = ssQuestions?.filter((q) => q.id !== id) || [];
    const result = await updateFormSsQuestions(formId, ssQuestionsToSSQuestionsInput(updatedQuestions));
    if (result) {
      setDeletingQuestion(null);
      enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
    } else {
      setDeletingQuestion(null);
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    }
  };

  return (
    <>
      {typeof ssQuestions === "undefined" || ssQuestions?.length === 0 ? (
        <div className="row" style={{ gap: "8px" }}>
          <Typography>{t("noSsQuestions")}</Typography>
          <Tooltip arrow title={t("addFirstQuestion")}>
            <AddBox color="primary" onClick={() => handleOpenModal()} />
          </Tooltip>
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <div
            style={{
              maxHeight: "620px",
              overflowY: "auto",
              padding: "16px",
            }}>
            {ssQuestions?.map((question) => (
              <Accordion key={question.id}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <div className="row" style={{ justifyContent: "space-between", width: "100%", gap: "8px" }}>
                    <Typography fontWeight={500}>- {question.title}</Typography>
                    <div className="row">
                      <Tooltip title={t("edit")}>
                        <Edit
                          sx={{ color: "rgba(0, 0, 0, 0.54)!important" }}
                          fontSize="small"
                          onClick={() => handleOpenModal(question)}
                        />
                      </Tooltip>
                      {isDeletingQuestion === question.id ? (
                        <CenteredContent loading loadingSize={12} />
                      ) : (
                        <Tooltip title={t("delete")}>
                          <Delete
                            sx={{ color: "rgba(0, 0, 0, 0.54)!important" }}
                            fontSize="small"
                            onClick={() => handleDeleteQuestion(question.id)}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {t("ssQuestions.type", { replace: { type: t(`ssQuestions.${question.type}`) } })}
                  </Typography>
                  {question.choices?.length ? (
                    <Typography>{t("ssQuestions.choices", { choices: question.choices.join(" / ") })}</Typography>
                  ) : undefined}
                  {question.notaBene?.length ? <Typography>{question.notaBene}</Typography> : undefined}
                  {typeof question.fieldId !== "undefined" && (question?.fieldId?.length || 0) > 0 ? (
                    <Typography>
                      {t("ssQuestions.fieldId", {
                        fieldId: visibleFields?.find((f) => f.id === question.fieldId)?.title,
                      })}
                    </Typography>
                  ) : undefined}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <div className="row" style={{ justifyContent: "space-between" }}>
            <Tooltip arrow title={t("add")}>
              <AddBox color="primary" onClick={() => handleOpenModal()} />
            </Tooltip>
            <Tooltip arrow title={t("seeSsQuestionsPreview")}>
              <Visibility color="primary" onClick={() => setOpenPreviewModal(true)} />
            </Tooltip>
          </div>
        </div>
      )}
      <SsQuestionModal
        open={modalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveQuestion}
        question={editingQuestion}
        visibleFields={visibleFields}
      />
      <SsQuestionsPreviewModal
        open={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        questions={ssQuestions}
        formId={formId}
      />
    </>
  );
};

SwornStatementConfig.defaultProps = {
  ssQuestions: undefined,
};

export default SwornStatementConfig;
