import { DataFieldInput, Maybe } from "../../data/generated/graphql";

export interface FormFieldChoice {
  name: string;
  value: string;
}

export interface ValueRange {
  fieldValue: string;
  range: string;
}

export interface FormChoiceRange {
  fieldId: string;
  valuesRanges: ValueRange[];
}

export interface SubNumberTitle {
  fieldId: string;
  fieldValue: string;
  title: string;
}

export interface ShowCondition {
  fieldId: string;
  fieldValues: string[];
}

export interface AttachmentShow {
  attachmentId: number;
  condition: ShowCondition;
}

export interface FormField {
  id: string;
  title?: string;
  type: "number" | "choice" | "value" | "multichoice" | "range" | "formula";
  choices?: FormFieldChoice[];
  value?: string;
  choiceRanges?: FormChoiceRange;
  titles?: SubNumberTitle[];
  showIf?: ShowCondition;
  skipValue?: boolean;
  minValue?: number;
  maxValue?: number;
}

export interface SsQuestion {
  id: string;
  title?: string;
  type: "number" | "choice" | "text" | "field";
  choices?: string[];
  notaBene?: string;
  fieldId?: string;
}
export interface Form {
  id: string;
  name: string;
  type: string;
  formUrl: string;
  fields: FormField[];
  attachments: string[];
  attachmentsShowIf?: AttachmentShow[];
  metDomTom?: number; // 1 = Metropole | 2 = DOM-TOM (like in getMetDomTom())
  startDate?: string;
  endDate?: string;
  history?: Form[];
  ssQuestions?: SsQuestion[];
}

export const getDisplayableFields = (fields: FormField[], data: Maybe<DataFieldInput>[]): FormField[] => {
  const result = fields
    ? fields.filter((ff) => ff.type === "number" || ff.type === "choice" || ff.type === "range")
    : [];
  return result.filter((f) => {
    if (f.showIf) {
      const dValue = data.find((d) => d?.fieldId === f.showIf?.fieldId)?.value;
      return f.showIf.fieldValues.findIndex((v) => v === dValue) !== -1;
    }
    return true;
  });
};

export const getAttachments = (form: Form, data: DataFieldInput[]): string[] => {
  if (form.attachmentsShowIf) {
    try {
      const result: string[] = [];
      // Put attachments not concerned by showIf
      form.attachments
        .filter((_, i) => form.attachmentsShowIf?.findIndex((asi) => asi.attachmentId === i) === -1)
        .map((a) => result.push(a));
      // Put the others if value is within the showIf
      form.attachmentsShowIf.forEach((asi) => {
        const asiValue = data.find((d) => d.fieldId === asi.condition.fieldId)?.value;
        if (asi.condition.fieldValues.indexOf(asiValue || "") !== -1) {
          result.push(form.attachments[asi.attachmentId]);
        }
      });
      return result;
    } catch (err) {
      throw new Error(`Getting conditionnal attachments failed: ${(err as Error).message}`);
    }
  }
  return form.attachments;
};

export const computeRange = (range?: string): number[] => {
  if (!range) return [];
  const bounds = range.split(">");
  const upper = parseInt(bounds[0], 10);
  const lower = parseInt(bounds[1], 10);
  const result = [];
  for (let i = upper; i >= lower; i -= 1) {
    result.push(i);
  }
  return result;
};
