import {
  AcUnit,
  Air,
  AirlineSeatReclineNormal,
  Apartment,
  CarRepair,
  DirectionsBusFilled,
  HelpOutline,
  Lightbulb,
  LocalFireDepartment,
  Moving,
  Plumbing,
  Settings,
  Warning,
} from "@mui/icons-material";
import { SvgIconTypeMap, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateConvertToString } from "../data/dataConvertors";
import { Form, SsQuestion } from "../data/generated/graphql";
import GdButton from "../utils/GdButton";
import GdModal from "../utils/GdModal";
import FormsPicto from "../utils/pictos/forms-picto";
import FormHistory from "./FormHistory";
import SwornStatementConfig from "./swornStatement/SwornStatementConfig";

interface FormViewerModalProps {
  open: boolean;
  onClose: () => void;
  form?: Form;
}

const getIconComponent = (
  familyName: string,
): OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  muiName: string;
} => {
  switch (familyName) {
    case "Froid":
      return AcUnit;
    case "Thermique":
      return LocalFireDepartment;
    case "Air comprimé":
      return Air;
    case "Moteur":
      return Settings;
    case "Bâtiment":
      return Apartment;
    case "Équipement":
      return Plumbing;
    case "Véhicule":
      return DirectionsBusFilled;
    case "Service":
      return CarRepair;
    case "Optimisation":
      return Moving;
    case "Chauffeur":
      return AirlineSeatReclineNormal;
    case "Éclairage":
      return Lightbulb;
    default:
      return HelpOutline;
  }
};

const getIcon = (familyName: string): ReactNode => {
  const Icon = getIconComponent(familyName);
  return <Icon style={{ fontSize: 48 }} />;
};

const FormViewerModal: FC<FormViewerModalProps> = ({ onClose, open, form }) => {
  const { t } = useTranslation("form");
  const [curTab, setCurTab] = useState(0);

  const getTabToDisplay = (): JSX.Element | undefined => {
    const filteredSsQuestions = form?.ssQuestions?.filter((q): q is SsQuestion => q !== null) || undefined;
    switch (curTab) {
      case 0:
        return <SwornStatementConfig ssQuestions={filteredSsQuestions} formId={form?.id || ""} />;
      case 1:
        return (form?.history?.length || 0) > 0 ? (
          <FormHistory history={form?.history || undefined} firstStartDate={form?.startDate || undefined} />
        ) : (
          <Typography>{t("noHistory")}</Typography>
        );

      case 2:
        return (form?.attachments?.length || 0) > 0 ? (
          <div style={{ width: "80%" }}>
            {form?.attachments?.map((attachment) => (
              <Typography key={attachment}>- {attachment}</Typography>
            ))}
          </div>
        ) : (
          <Typography>{t("noAttachment")}</Typography>
        );
      default:
        return undefined;
    }
  };

  return (
    <GdModal
      onClose={onClose}
      open={open}
      maxHeight="1000px"
      maxWidth="1100px"
      // eslint-disable-next-line react/no-children-prop
      children={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <div className="row" style={{ width: "100%", justifyContent: "space-between", alignItems: "flex-start" }}>
            <FormsPicto width={180} height={180} fill="#638E2F" />
            <div
              style={{
                maxWidth: "70%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: "8px",
              }}>
              <div
                className="row"
                style={{ gap: "18px", width: "100%", justifyContent: "space-between", alignItems: "flex-end" }}>
                <div className="row" style={{ gap: "8px" }}>
                  <Typography variant="h5" fontWeight={500}>
                    {form?.id}
                  </Typography>
                  {(form?.startDate || 0) > new Date().toISOString() ? (
                    <Tooltip
                      arrow
                      title={t("isNotApplicableYet", {
                        replace: { date: dateConvertToString(form?.startDate || undefined) },
                      })}>
                      <Warning color="warning" />
                    </Tooltip>
                  ) : undefined}
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <Typography variant="h6">{form?.type || ""}</Typography>
                  {getIcon(form?.type || "")}
                </div>
              </div>
              <Typography align="justify">{form?.name}</Typography>
              <GdButton
                label={t("seeForm")}
                onClick={() => window.open(form?.formUrl || "")}
                className="margin-left"
                nowrap
              />
            </div>
          </div>

          <Tabs value={curTab} onChange={(_, value) => setCurTab(value)}>
            <Tab label={t("modalTabs.swornStatement", { count: form?.ssQuestions?.length || 0 })} />
            <Tab label={t("modalTabs.history", { count: form?.history?.length || 0 })} />
            <Tab label={t("modalTabs.attachment", { count: form?.attachments?.length || 0 })} />
          </Tabs>
          <div className="margin-top">{getTabToDisplay()}</div>
        </div>
      }
    />
  );
};

FormViewerModal.defaultProps = {
  form: undefined,
};

export default FormViewerModal;
