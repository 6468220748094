import { Check, ExpandMore, InsertDriveFile, Subtitles, SubtitlesOff } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { FormsContext } from "../data/contexts/FormsContext";
import { Form, Sector } from "../data/generated/graphql";
import BaseCard from "../utils/BaseCard";
import CenteredContent from "../utils/CenteredContent";
import FormViewerModal from "./FormViewerModal";

const Forms: FC = () => {
  const { t } = useTranslation("form");
  const { stats, getFormsStats, forms, getAllForms, sectorForms } = useContext(FormsContext);
  const [selectedForm, setSelectedForm] = useState<Form | undefined>(undefined);
  const [usedForms, setUsedForms] = useState<boolean>(false);
  const [onlyFormsWithSsQuestions, setOnlyFormsWithSsQuestions] = useState<boolean | null>(null);

  useEffect(() => {
    getFormsStats();
    getAllForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedForm(forms.find((form) => form.id === selectedForm?.id));
  }, [forms, sectorForms, selectedForm?.id]);

  const top10Forms = stats.FormUsage.slice(0, 10);

  const filteredSectorForms = useMemo((): Record<Sector, Form[]> => {
    let tmpForms = sectorForms;

    if (onlyFormsWithSsQuestions === true) {
      tmpForms = Object.fromEntries(
        Object.entries(tmpForms).map(([sector, formsOfSector]) => [
          sector,
          formsOfSector.filter((form) => (form?.ssQuestions?.length || 0) > 0),
        ]),
      ) as Record<Sector, Form[]>;
    }
    if (onlyFormsWithSsQuestions === false) {
      tmpForms = Object.fromEntries(
        Object.entries(tmpForms).map(([sector, formsOfSector]) => [
          sector,
          formsOfSector.filter((form) => (form?.ssQuestions?.length || 0) === 0),
        ]),
      ) as Record<Sector, Form[]>;
    }

    const usedFormIds = stats.FormUsage.map((usage) => usage.formId);
    if (usedForms) {
      tmpForms = Object.fromEntries(
        Object.entries(tmpForms).map(([sector, formsOfSector]) => [
          sector,
          formsOfSector.filter((form) => usedFormIds.includes(form.id)),
        ]),
      ) as Record<Sector, Form[]>;
    }

    return tmpForms;
  }, [sectorForms, onlyFormsWithSsQuestions, stats.FormUsage, usedForms]);

  const handleChangeOnlyFormsWithSsQuestions = (event: React.MouseEvent<HTMLElement>, value: boolean | null): void => {
    setOnlyFormsWithSsQuestions(value);
  };

  return (
    <>
      <BaseCard>
        <Typography align="center" variant="h4">
          {t("title")}
        </Typography>
        {top10Forms.length === 0 ? (
          <CenteredContent loading />
        ) : (
          <>
            <Card
              sx={{
                padding: "12px",
                margin: "32px 0",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <Typography align="center" variant="h5" fontWeight={400}>
                {t("stats")}
              </Typography>

              <Typography align="center" variant="subtitle1">
                {t("top10")}
              </Typography>
              <BarChart width={1000} height={250} data={top10Forms} maxBarSize={top10Forms[0]?.usage + 1}>
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis dataKey="formId" fontSize={12} tickMargin={8} />
                <YAxis domain={[0, top10Forms[0]?.usage + 1]} interval={1} />
                <Tooltip />
                <Legend />
                <Bar dataKey="usage" fill="#638E2F" barSize={50} animationBegin={50} name={t("usage")} />
              </BarChart>
              <div style={{ alignSelf: "flex-start" }}>
                <Typography>{t("totalOfProjects", { count: stats.totalOfProjects })}</Typography>
                <Typography>{t("nbOfUniqueFormsUsage", { count: stats.nbOfUniqueFormsUsage })}</Typography>
                <Typography>{t("nbOfFormConfig", { count: forms.length })}</Typography>
              </div>
            </Card>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "12px",
                gap: "12px",
              }}>
              <Typography align="center" variant="h5" fontWeight={400}>
                {t("formCatalog")}
              </Typography>
              <div className="row" style={{ gap: "12px" }}>
                <Typography>{t("seeOnlyUsedForms")}</Typography>
                <ToggleButton
                  sx={{ width: "12px", height: "12px", backgroundColor: usedForms ? "#638E2F!important" : undefined }}
                  value="check"
                  selected={usedForms}
                  onChange={() => setUsedForms((prevSelected) => !prevSelected)}>
                  <Check sx={{ color: usedForms ? "white!important" : "#638E2F!important" }} fontSize="small" />
                </ToggleButton>
              </div>
              <div className="row" style={{ gap: "12px" }}>
                <Typography>{t("seeOnlyFormsWithSsQuestions")}</Typography>
                <div>
                  <ToggleButtonGroup
                    exclusive
                    value={onlyFormsWithSsQuestions}
                    onChange={handleChangeOnlyFormsWithSsQuestions}
                    size="small">
                    <ToggleButton
                      sx={{
                        padding: "8px!important",
                        width: "32px",
                        height: "32px",
                        backgroundColor: onlyFormsWithSsQuestions ? "#638E2F!important" : undefined,
                      }}
                      value>
                      <Subtitles
                        fontSize="small"
                        sx={{ color: onlyFormsWithSsQuestions ? "white!important" : "#638E2F!important" }}
                      />
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        padding: "8px!important",
                        margin: "0!important",
                        width: "32px",
                        height: "32px",
                        backgroundColor: onlyFormsWithSsQuestions === false ? "#638E2F!important" : undefined,
                      }}
                      value={false}>
                      <SubtitlesOff
                        fontSize="small"
                        sx={{ color: onlyFormsWithSsQuestions === false ? "white!important" : "#638E2F!important" }}
                      />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                {Object.keys(filteredSectorForms).map((sector) => (
                  <Accordion key={sector} sx={{ padding: "12px", width: "100%" }}>
                    <AccordionSummary sx={{ width: "100%" }} expandIcon={<ExpandMore />}>
                      <Typography variant="h6">{`${sector} : (${
                        filteredSectorForms[sector as Sector].length
                      })`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "center", gap: "12px" }}>
                      {filteredSectorForms[sector as Sector].map((form) => (
                        <Card
                          key={form.id}
                          onClick={() => setSelectedForm(form)}
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "12px",
                          }}>
                          <Typography sx={{ fontWeight: "500" }}>{form.id}</Typography>
                          <InsertDriveFile width={32} height={32} color="primary" />
                        </Card>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          </>
        )}
      </BaseCard>
      <FormViewerModal
        onClose={() => setSelectedForm(undefined)}
        open={typeof selectedForm !== "undefined"}
        form={selectedForm}
      />
    </>
  );
};

export default Forms;
