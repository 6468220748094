import { Modal, Paper } from "@mui/material";
import { FC, PropsWithChildren } from "react";

interface GdModalProps extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
  maxHeight?: string;
  maxWidth?: string;
}

const GdModal: FC<GdModalProps> = ({ open, onClose, children, maxHeight, maxWidth }) => (
  <Modal open={open} onClose={onClose} className="project-modal-root">
    <Paper className="project-modal-paper charter-modal" sx={{ maxWidth, maxHeight }}>
      {children}
    </Paper>
  </Modal>
);

GdModal.defaultProps = {
  maxHeight: "850px",
  maxWidth: "850px",
};

export default GdModal;
