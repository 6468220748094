import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  DefaultContext,
  InMemoryCache,
  useApolloClient,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useSnackbar } from "notistack";
import { FC, PropsWithChildren, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { log, Lvl } from "../utils/log";
import { TokenContext } from "./contexts/TokenContext";

const GdApolloProviderEnhancer: FC<PropsWithChildren> = ({ children }) => {
  const GetToken = useRef<() => Promise<string | null>>();
  const { token, expires, getToken } = useContext(TokenContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("global");
  const apolloClient = useApolloClient();

  const showError = (err: unknown, network?: boolean): void => {
    log(err, Lvl.ERROR);
    if (network) enqueueSnackbar(t("networkError"), { variant: "error" });
  };

  useEffect(() => {
    GetToken.current = getToken;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expires, token]);

  const httpLink = createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_URL });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      showError(graphQLErrors);
    }
    if (networkError) {
      showError(networkError, true);
    }
    return forward(operation);
  });

  const authLink = setContext((_: unknown, prevContext: DefaultContext): Promise<DefaultContext> | DefaultContext => {
    return (
      GetToken.current?.()
        .then((finalToken: string | null) => {
          return {
            ...prevContext,
            headers: prevContext.headers
              ? { ...prevContext.headers, authorization: finalToken ? `Bearer ${finalToken}` : "" }
              : { authorization: finalToken ? `Bearer ${finalToken}` : "" },
          };
        })
        .catch(() => prevContext) || prevContext
    );
  });

  apolloClient.setLink(ApolloLink.from([authLink, errorLink, httpLink]));

  return <>{children}</>;
};

const GdApolloProvider: FC<PropsWithChildren> = ({ children }) => (
  <ApolloProvider
    client={
      new ApolloClient({
        uri: process.env.REACT_APP_GRAPHQL_API_URL,
        cache: new InMemoryCache({
          typePolicies: {
            Operation: {
              keyFields: ["id", "computation"],
            },
            Field: {
              keyFields: false,
            },
          },
        }),
        defaultOptions: {
          query: {
            fetchPolicy: "no-cache",
            errorPolicy: "ignore",
          },
        },
      })
    }>
    <GdApolloProviderEnhancer>{children}</GdApolloProviderEnhancer>
  </ApolloProvider>
);

export default GdApolloProvider;
