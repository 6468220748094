import { Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataField, SsQuestion } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import GdModal from "../../utils/GdModal";
import FirstSsQuestionsBlock from "./FirstSsQuestionsBlock";
import ResultSsQuestions from "./ResultSsQuestions";
import SsQuestionsFields from "./SsQuestionsFields";
import { firstBlockQuestions_mock, operation_mock, ssData_mock } from "./mock";
import { FormsContext } from "../../data/contexts/FormsContext";

interface SsQuestionModalProps {
  onClose: () => void;
  open: boolean;
  questions?: SsQuestion[];
  formId?: string;
}

const SsQuestionsPreviewModal: FC<SsQuestionModalProps> = ({ questions, onClose, open, formId }) => {
  const { t } = useTranslation("form");
  const { forms } = useContext(FormsContext);
  const form = forms.find((f) => f.id === formId);
  const visibleFields = form?.fields;
  const [resultMode, setResultMode] = useState(false);
  const [ssData, setSsData] = useState<DataField[]>(ssData_mock);
  const handleChangeData = (fieldId: string, value: string): void => {
    const dataId = ssData.findIndex((s) => s.fieldId === fieldId);
    const newSsData = [...ssData];
    if (dataId >= 0) {
      newSsData[dataId] = { fieldId, value };
    } else {
      newSsData.push({ fieldId, value });
    }
    setSsData(newSsData);
  };
  const close = (): void => {
    onClose();
    setResultMode(false);
  };
  return (
    <GdModal open={open} onClose={close} maxHeight="1000px" maxWidth="1200px">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography variant="h6" align="center">
          {t("previewModal.title", { replace: { form: formId } })}
        </Typography>
        <div
          style={{
            maxHeight: "850px",
            overflowY: "auto",
            width: "100%",
            margin: "16px 0",
            border: "1px solid #C4C4C4",
            borderRadius: "5px",
            padding: "16px",
          }}>
          {resultMode ? (
            <ResultSsQuestions
              ssData={ssData}
              questions={firstBlockQuestions_mock.concat(questions || [])}
              visibleFields={visibleFields}
            />
          ) : (
            <div style={{ display: "flex", flexDirection: "column", gap: "16px", margin: "16px 0" }}>
              <FirstSsQuestionsBlock ssData={ssData} onChange={handleChangeData} questions={firstBlockQuestions_mock} />
              {questions?.map((question) => (
                <SsQuestionsFields
                  key={question.id}
                  field={question}
                  onChange={handleChangeData}
                  visibleFields={visibleFields}
                  data={ssData.find((s) => s.fieldId === question.id)}
                  operation={operation_mock}
                />
              ))}
            </div>
          )}
        </div>
        <div className="row" style={{ width: "100%", justifyContent: "center", gap: "12px" }}>
          <GdButton label={t("previewModal.closeButton")} color="error" onClick={close} />
          <GdButton
            label={t(resultMode ? "previewModal.ssQuestionsFormButton" : "previewModal.resultButton")}
            onClick={() => setResultMode(!resultMode)}
          />
        </div>
      </div>
    </GdModal>
  );
};

SsQuestionsPreviewModal.defaultProps = {
  questions: undefined,
  formId: undefined,
};

export default SsQuestionsPreviewModal;
