/* eslint-disable @typescript-eslint/naming-convention */
import { DataField, Operation, SsQuestion } from "../../data/generated/graphql";

export const operation_mock: Operation = {
  id: "1",
  isReplacement: false,
  formId: "BAT-TH-116",
  data: [
    {
      fieldId: "class",
      value: "1",
    },
    {
      fieldId: "sector",
      value: "2",
    },
    {
      fieldId: "usage",
      value: "3",
    },
    {
      fieldId: "area",
      value: "50000",
    },
  ],
  cdpData: [],
  bonusDetails: [],
  computation: {
    capacity: "3905.0000000000005",
    valuation: "26944.500000000004",
    validity: "2024-11-30T13:16:22.237Z",
    cpeBonus: null,
    computationDate: "2024-11-05T14:33:42.445Z",
  },
};

export const firstBlockQuestions_mock: SsQuestion[] = [
  { id: "engageDate", title: "Date d’engagement de l'opération (ex : date d’acceptation du devis)" },
  { id: "invoiceDate", title: "Date de preuve de réalisation de l’opération (ex : date de la facture)" },
  { id: "invoiceNumber", title: "Référence de la facture" },
  { id: "workSiteName", title: "Nom du site des travaux ou nom de la copropriété" },
  { id: "workSiteAddress", title: "Adresse des travaux" },
  { id: "workSiteZipCode", title: "Code postal" },
  { id: "workSiteCity", title: "Ville" },
];

export const ssData_mock: DataField[] = [
  { fieldId: "engageDate", value: "2024-10-10T15:50:37.327Z" },
  { fieldId: "invoiceDate", value: "2024-11-03T15:50:37.327Z" },
  { fieldId: "invoiceNumber", value: "1234567890" },
  { fieldId: "workSiteName", value: "Douze building" },
  { fieldId: "workSiteAddress", value: "12 du de Douze" },
  { fieldId: "workSiteZipCode", value: "33600" },
  { fieldId: "workSiteCity", value: "Pessac" },
];
