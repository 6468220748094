import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FC } from "react";

interface GdDatePickerProps {
  label: string;
  onChange: (value: Date | null) => void;
  value: Date | null;
  disabled: boolean;
  className?: string;
  minDate?: Date;
  fullWidth?: boolean;
}

const GdDatePicker: FC<GdDatePickerProps> = ({ label, onChange, value, disabled, className, minDate, fullWidth }) => (
  <DatePicker
    label={label}
    className={className}
    format="dd/MM/yyyy"
    defaultValue={value}
    onChange={(d: Date | null) => {
      if (d === null) onChange(d);
      else {
        // Get rid of all these f***ing timezone problems by setting hours to 6
        const finalDate = new Date(d);
        finalDate.setHours(6);
        onChange(finalDate);
      }
    }}
    disabled={disabled}
    minDate={minDate}
    timezone="UTC"
    slotProps={{
      textField: { inputProps: { placeholder: "jj/mm/aaaa" }, variant: "outlined", fullWidth },
    }}
  />
);

GdDatePicker.defaultProps = { className: undefined, minDate: undefined, fullWidth: false };

export default GdDatePicker;
