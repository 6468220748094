import { Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { dateConvertToString } from "../data/dataConvertors";
import { FormVersion } from "../data/generated/graphql";
import GdButton from "../utils/GdButton";

interface FormHistoryProps {
  history?: FormVersion[];
  firstStartDate?: string;
}

const FormHistory: FC<FormHistoryProps> = ({ history, firstStartDate }) => {
  const { t } = useTranslation("form");
  return (
    <ul style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {history?.map((form, index) => (
        <li className="row">
          <Typography>
            {t("versionEndDate", {
              replace: {
                date: dateConvertToString(index === 0 ? firstStartDate : history[index - 1].startDate || undefined),
              },
            })}
          </Typography>
          <GdButton
            label={t("seeForm")}
            onClick={() => window.open(form?.formUrl || "")}
            className="margin-left"
            nowrap
          />
        </li>
      ))}
    </ul>
  );
};

FormHistory.defaultProps = {
  history: undefined,
  firstStartDate: undefined,
};

export default FormHistory;
