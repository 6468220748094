import { TextField } from "@mui/material";
import { FC } from "react";
import { DataField, SsQuestion } from "../../data/generated/graphql";
import GdDatePicker from "../../utils/GdDatePicker";

interface FirstSsQuestionsBlockProps {
  ssData: DataField[];
  onChange: (fieldId: string, value: string) => void;
  questions?: SsQuestion[];
}

const FirstSsQuestionsBlock: FC<FirstSsQuestionsBlockProps> = ({ ssData, onChange, questions }) => {
  return (
    <>
      <div className="row" style={{ gap: "8px" }}>
        <GdDatePicker
          fullWidth
          label={questions?.find((q) => q.id === "engageDate")?.title || ""}
          value={new Date(ssData.find((s) => s.fieldId === "engageDate")?.value || "")}
          onChange={(date) => onChange("engageDate", date?.toISOString() || "")}
          disabled={false}
        />
        <GdDatePicker
          fullWidth
          label={questions?.find((q) => q.id === "invoiceeDate")?.title || ""}
          value={new Date(ssData.find((s) => s.fieldId === "invoiceDate")?.value || "")}
          onChange={(date) => onChange("invoiceDate", date?.toISOString() || "")}
          disabled={false}
        />
      </div>
      <TextField
        id="outlined-number"
        label={questions?.find((q) => q.id === "invoiceNumber")?.title || ""}
        value={ssData.find((s) => s.fieldId === "invoiceNumber")?.value || ""}
        onChange={(e) => onChange("invoiceNumber", e.target.value)}
      />
      <div className="row" style={{ gap: "8px" }}>
        <TextField
          fullWidth
          id="outlined-number"
          label={questions?.find((q) => q.id === "workSiteName")?.title || ""}
          value={ssData.find((s) => s.fieldId === "workSiteName")?.value || ""}
          onChange={(e) => onChange("workSiteName", e.target.value)}
        />
        <TextField
          fullWidth
          id="outlined-number"
          label={questions?.find((q) => q.id === "workSiteAddress")?.title || ""}
          value={ssData.find((s) => s.fieldId === "workSiteAddress")?.value || ""}
          onChange={(e) => onChange("workSiteAddress", e.target.value)}
        />
      </div>
      <div className="row" style={{ gap: "8px" }}>
        <TextField
          fullWidth
          id="outlined-number"
          label={questions?.find((q) => q.id === "workSiteZipCode")?.title || ""}
          value={ssData.find((s) => s.fieldId === "workSiteZipCode")?.value || ""}
          onChange={(e) => onChange("workSiteZipCode", e.target.value)}
        />
        <TextField
          fullWidth
          id="outlined-number"
          label={questions?.find((q) => q.id === "workSiteCity")?.title || ""}
          value={ssData.find((s) => s.fieldId === "workSiteCity")?.value || ""}
          onChange={(e) => onChange("workSiteCity", e.target.value)}
        />
      </div>
    </>
  );
};

FirstSsQuestionsBlock.defaultProps = {
  questions: undefined,
};

export default FirstSsQuestionsBlock;
