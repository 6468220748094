import React, { createContext, useContext, useEffect, useState } from "react";
import { defaultSectorForms, formsToSectorForms } from "../../projects/form/FormHelper";
import {
  AllFormsStats,
  Form,
  Sector,
  SsQuestionInput,
  useAddSsQuestionsToFormMutation,
  useGetAllFormsQuery,
  useGetFormsStatsQuery,
} from "../generated/graphql";
import { TokenContext } from "./TokenContext";

interface FormCache {
  formId: string;
  lastFetch: number;
}

export interface FormsContextProps {
  stats: AllFormsStats;
  getFormsStats: () => Promise<boolean>;
  forms: Form[];
  sectorForms: Record<Sector, Form[]>;
  getAllForms: () => Promise<boolean>;
  updateFormSsQuestions: (formId: string, ssQuestions: SsQuestionInput[]) => Promise<boolean>;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}

const initialContext: FormsContextProps = {
  stats: {
    FormUsage: [],
    nbOfUniqueFormsUsage: 0,
    totalOfProjects: 0,
  },
  sectorForms: defaultSectorForms(),
  getFormsStats: () => Promise.resolve(false),
  forms: [],
  getAllForms: () => Promise.resolve(false),
  updateFormSsQuestions: () => Promise.resolve(false),
  isEditing: false,
  setIsEditing: () => {},
};

export const FormsContext = createContext<FormsContextProps>(initialContext);

export const FormsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { getToken } = useContext(TokenContext);
  const [forms, setForms] = useState<Form[]>([]);
  const [formCache, setFormCache] = useState<FormCache[]>([]);
  const [sectorForms, setSectorForms] = useState<Record<Sector, Form[]>>(defaultSectorForms());
  const [stats, setStats] = useState<AllFormsStats>(initialContext.stats);
  const [isEditing, setIsEditing] = useState(false);
  const { refetch: refetchAllForms } = useGetAllFormsQuery({ skip: true });
  const { refetch: refetchFormsStats } = useGetFormsStatsQuery({ skip: true });
  const [addSsQuestionsToForm] = useAddSsQuestionsToFormMutation();

  useEffect(() => {
    setSectorForms(formsToSectorForms(forms));
  }, [forms]);

  const updateCache = (form: Form): void => {
    let found = false;
    const newForms = forms.map((u) => {
      if (u.id === form.id) return form;
      return u;
    });
    setForms(newForms);
    const newFormCache = formCache.map((fc) => {
      if (fc.formId === form.id) {
        found = true;
        return { ...fc, lastFetch: new Date().getTime() };
      }
      return fc;
    });
    if (!found) newFormCache.push({ formId: form.id, lastFetch: new Date().getTime() });
    setFormCache(newFormCache);
  };

  const getAllForms = async (): Promise<boolean> => {
    const result = await refetchAllForms();
    if (result && result.data) {
      setForms(result.data.formGetAll || []);
      return true;
    }

    return false;
  };

  const getFormsStats = async (): Promise<boolean> => {
    const result = await refetchFormsStats();
    if (result && result.data) {
      setStats(result.data.allFormsStats);
      return true;
    }

    return false;
  };

  const updateFormSsQuestions = async (formId: string, ssQuestions: SsQuestionInput[]): Promise<boolean> => {
    setIsEditing(true);
    const token = await getToken();
    const result = await addSsQuestionsToForm({
      variables: { formId, ssQuestions },
      context: { headers: { Authorization: `Bearer ${token}` } },
    });
    if (result && result.data) {
      updateCache(result.data.addSsQuestionsToForm);
      setIsEditing(false);
      return true;
    }
    setIsEditing(false);
    return false;
  };

  return (
    <FormsContext.Provider
      value={{ stats, getFormsStats, forms, sectorForms, getAllForms, updateFormSsQuestions, isEditing, setIsEditing }}>
      {children}
    </FormsContext.Provider>
  );
};
