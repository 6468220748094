import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FC, useState } from "react";
import {
  DataField,
  Field,
  FieldType,
  Maybe,
  Operation,
  SsQuestion,
  SsQuestionsType,
} from "../../data/generated/graphql";
import { TextMaskNumber } from "../../utils/TextMasks";

interface FieldProps {
  onChange: (fieldId: string, value: string) => void;
  field?: SsQuestion;
  data?: DataField;
  operation?: Operation;
  visibleFields?: Maybe<Field[]>;
}

const SsQuestionsFields: FC<FieldProps> = ({ field, visibleFields, data, onChange, operation }) => {
  const operationData = operation?.data.find((d) => d?.fieldId === field?.fieldId || "");
  const visibleField = visibleFields?.find((f) => f.id === field?.fieldId);
  const fieldType = visibleFields?.find((f) => f.id === field?.fieldId)?.type;
  const [newValue, setNewValue] = useState(
    fieldType === FieldType.Choice
      ? visibleField?.choices?.find((c) => c.value === operationData?.value)?.value
      : operation?.data.find((d) => d?.fieldId === field?.fieldId || "")?.value,
  );
  if (field?.type === SsQuestionsType.Number) {
    return (
      <TextField
        variant="outlined"
        label={field?.title}
        value={data?.value || ""}
        onChange={(e) => onChange(field?.id, e.target.value)}
        InputProps={{
          inputComponent: TextMaskNumber as any,
        }}
        fullWidth
      />
    );
  }
  if (field?.type === SsQuestionsType.Choice) {
    return (
      <FormControl fullWidth>
        <InputLabel id={`field-selector-${field?.id}`}>{field?.title}</InputLabel>
        <Select
          labelId={`field-selector-${field?.id}`}
          variant="outlined"
          label={field?.title}
          value={field.choices?.find((c) => c === data?.value) || ""}
          fullWidth
          onChange={(e) => onChange(field?.id, e.target.value as string)}
          style={{ textAlign: "left" }}>
          {field?.choices?.map((fc) => (
            <MenuItem key={fc as string} value={fc as string}>
              {fc}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  if (field?.type === SsQuestionsType.Text) {
    return (
      <TextField
        variant="outlined"
        label={field?.title}
        value={data?.value || ""}
        onChange={(e) => onChange(field.id, e.target.value)}
        fullWidth
      />
    );
  }

  if (field?.type === SsQuestionsType.Field) {
    if (fieldType === FieldType.Number) {
      return (
        <div>
          <TextField
            variant="outlined"
            label={visibleFields?.find((f) => f.id === field.fieldId)?.title}
            value={newValue || ""}
            disabled
            onChange={(e) => {
              onChange(field?.id, e.target.value);
              setNewValue(e.target.value);
            }}
            InputProps={{
              inputComponent: TextMaskNumber as any,
            }}
            fullWidth
          />
        </div>
      );
    }
    if (fieldType === FieldType.Choice) {
      return (
        <FormControl fullWidth>
          <InputLabel id={`field-selector-${field?.id}`}>{field?.title}</InputLabel>
          <Select
            labelId={`field-selector-${field?.id}`}
            variant="outlined"
            label={field?.title}
            fullWidth
            disabled
            value={newValue || ""}
            onChange={(e) => {
              setNewValue(e.target.value || "");
              onChange(field?.id, e.target.value as string);
            }}
            style={{ textAlign: "left" }}>
            {visibleFields
              ?.find((v) => v.id === field?.fieldId)
              ?.choices?.map((c) => (
                <MenuItem value={c.value as string} key={c.name}>
                  {c.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    }
  }
  return null;
};

SsQuestionsFields.defaultProps = {
  field: undefined,
  visibleFields: undefined,
  data: undefined,
  operation: undefined,
};

export default SsQuestionsFields;
