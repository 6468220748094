import { Typography } from "@mui/material";
import { FC } from "react";
import { DataField, Field, FieldType, Maybe, SsQuestion, SsQuestionsType } from "../../data/generated/graphql";

interface ResultSsQuestionProps {
  result: {
    id: string;
    value: string;
    question: SsQuestion | undefined;
    fieldId: string;
  };
  visibleFields?: Maybe<Field[]>;
}
const ResultSsQuestion: FC<ResultSsQuestionProps> = ({ result, visibleFields }) => {
  const type = result.question?.type;
  const visibleField = visibleFields?.find((f) => f.id === result?.question?.fieldId);
  const choiceValue = visibleField?.choices?.find((c) => c.value === result?.value)?.value;
  const newValue = visibleField?.choices?.find((c) => c.value === choiceValue)?.name;
  const newResult = {
    ...result,
    value: type === SsQuestionsType.Field && visibleField?.type === FieldType.Choice ? newValue : result.value,
  };
  return (
    <div key={result.id} className="row" style={{ gap: "8px" }}>
      <div className="row" style={{ gap: "8px" }}>
        -<Typography sx={{ textDecoration: "underline" }}>{newResult.question?.title}</Typography>:
      </div>
      <Typography fontWeight={600}>{newResult.value}</Typography>
    </div>
  );
};
ResultSsQuestion.defaultProps = {
  visibleFields: undefined,
};

interface ResultSsQuestionsProps {
  ssData: DataField[];
  questions?: SsQuestion[];
  visibleFields?: Maybe<Field[]>;
}
const ResultSsQuestions: FC<ResultSsQuestionsProps> = ({ ssData, questions, visibleFields }) => {
  const results = ssData.map((s) => ({
    id: s.fieldId,
    value: s.value,
    question: questions?.find((q) => q.id === s.fieldId),
    fieldId: s.fieldId,
  }));
  return (
    <div>
      {results.map((result) => (
        <ResultSsQuestion result={result} visibleFields={visibleFields} />
      ))}
    </div>
  );
};

ResultSsQuestions.defaultProps = {
  questions: undefined,
  visibleFields: undefined,
};

export default ResultSsQuestions;
