import { Warning } from "@mui/icons-material";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormsContext } from "../../data/contexts/FormsContext";
import { Field, FieldType, SsQuestion, SsQuestionsType } from "../../data/generated/graphql";
import CenteredContent from "../../utils/CenteredContent";
import GdModal from "../../utils/GdModal";
import Choices from "./Choices";

interface SsQuestionModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (question: SsQuestion) => Promise<void>;
  question?: SsQuestion;
  visibleFields?: Field[];
}

const initialQuestion = {
  id: "",
  title: "",
  type: undefined,
  choices: [],
  notaBene: "",
  fieldId: "",
};

const SsQuestionModal: React.FC<SsQuestionModalProps> = ({ open, onClose, onSave, question, visibleFields }) => {
  const { t } = useTranslation("form");
  const { isEditing } = useContext(FormsContext);
  const [editingQuestion, setEditingQuestion] = useState<SsQuestion>(initialQuestion);

  useEffect(() => {
    if (typeof question !== "undefined") {
      setEditingQuestion(question);
    } else {
      setEditingQuestion(initialQuestion);
    }
  }, [question]);

  const closeModal = (): void => {
    setEditingQuestion(initialQuestion);
    onClose();
  };

  const validator =
    (editingQuestion?.title?.length || 0) > 0 &&
    typeof editingQuestion?.type !== "undefined" &&
    (editingQuestion?.type === SsQuestionsType.Field ? (editingQuestion?.fieldId?.length || 0) > 0 : true) &&
    (editingQuestion?.type === SsQuestionsType.Choice ? (editingQuestion?.choices?.length || 0) > 1 : true);

  const handleSave = async (): Promise<void> => {
    await onSave(editingQuestion);
    setEditingQuestion(initialQuestion);
  };

  const handleChangeType = (value: string): void => {
    setEditingQuestion({ ...editingQuestion, type: value as SsQuestionsType });
  };

  return (
    <GdModal open={open} onClose={onClose} maxHeight="100%">
      <div style={{ maxWidth: "800px" }}>
        <Typography variant="h6" align="center">
          {question ? t("ssQuestionsModal.editQuestion") : t("ssQuestionsModal.addQuestion")}
        </Typography>
        <TextField
          fullWidth
          label={t("ssQuestionsModal.title")}
          value={editingQuestion?.title}
          onChange={(e) => setEditingQuestion({ ...editingQuestion, title: e.target.value })}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="input-label-position">{t("ssQuestionsModal.type")}</InputLabel>
          <Select
            label={t("ssQuestionsModal.type")}
            labelId="input-label-position"
            value={editingQuestion.type || ""}
            onChange={(e) => handleChangeType(e.target.value || "")}>
            {Object.keys(SsQuestionsType).map((ty) => (
              <MenuItem key={ty} value={ty}>
                {t(`ssQuestionsModal.${ty}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {editingQuestion?.type === SsQuestionsType.Choice && (
          <Choices
            defaultChoices={editingQuestion.choices || []}
            updateChoices={(e) => setEditingQuestion({ ...editingQuestion, choices: e as string[] })}
          />
        )}

        {editingQuestion?.type === SsQuestionsType.Field && (
          <FormControl fullWidth margin="normal">
            <InputLabel id="input-label-position">{t("ssQuestionsModal.fieldId")}</InputLabel>
            <Select
              label={t("ssQuestionsModal.fieldId")}
              labelId="input-label-position"
              value={visibleFields?.find((f) => f.id === editingQuestion.fieldId)?.id || ""}
              onChange={(e) => setEditingQuestion({ ...editingQuestion, fieldId: e.target.value })}>
              {visibleFields
                ?.filter((v) => v.type === FieldType.Choice || v.type === FieldType.Number)
                .map((vf) => (
                  <MenuItem key={vf.id} value={vf.id}>
                    {vf?.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <TextField
          fullWidth
          label={t("ssQuestionsModal.notaBene")}
          value={editingQuestion?.notaBene}
          onChange={(e) => setEditingQuestion({ ...editingQuestion, notaBene: e.target.value })}
          margin="normal"
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginTop: "24px" }}>
          {!validator && (
            <div className="row" style={{ gap: "8px", justifyContent: "center" }}>
              <Warning color="error" style={{ fontSize: "12px" }} />
              <Typography align="center" color="error" style={{ fontSize: "12px" }}>
                {t("ssQuestionsModal.isMandatory")}
              </Typography>
            </div>
          )}
          {isEditing ? (
            <CenteredContent loading />
          ) : (
            <div className="row" style={{ width: "100%", justifyContent: "center", gap: "12px" }}>
              <Button onClick={closeModal} variant="contained" color="warning">
                {t("ssQuestionsModal.cancel")}
              </Button>
              <Button onClick={handleSave} variant="contained" color="primary" disabled={!validator}>
                {t("ssQuestionsModal.save")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </GdModal>
  );
};

SsQuestionModal.defaultProps = {
  question: undefined,
  visibleFields: undefined,
};

export default SsQuestionModal;
