import { AddBox, Delete } from "@mui/icons-material";
import { Button, FormControl, IconButton, InputLabel, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ChoiceProps {
  defaultChoices: string[];
  updateChoices: (newChoices: string[]) => void;
}

const Choices: FC<ChoiceProps> = ({ defaultChoices, updateChoices }) => {
  const { t } = useTranslation("form");
  const [choices, setChoices] = useState<string[]>([]);

  useEffect(() => {
    setChoices(defaultChoices);
  }, [defaultChoices]);

  const addNewChoice = (): void => {
    setChoices([...choices, ""]);
  };

  const deleteChoice = (index: number): void => {
    const newChoices = choices?.filter((_, i) => i !== index);
    setChoices(newChoices);
    updateChoices(newChoices);
  };

  const handleChoiceBlur = (index: number, value: string): void => {
    const newChoices = [...choices];
    newChoices[index] = value;
    setChoices(newChoices);
    updateChoices(newChoices);
  };

  return (
    <FormControl fullWidth margin="normal" sx={{ padding: "8px" }}>
      <InputLabel shrink htmlFor="choices-container" sx={{ top: "8px" }}>
        {t("ssQuestionsModal.choices")}
      </InputLabel>
      <div
        className="choices-container"
        style={{
          border: "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: "4px",
          padding: "16px",
          marginTop: "8px",
        }}>
        <div style={{ overflowY: "auto", maxHeight: "600px" }}>
          {choices?.map((choice, index) => (
            <div key={`${choice}_${index + 1}`} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              <TextField
                fullWidth
                label={`${t("ssQuestionsModal.Choice")} ${index + 1}`}
                defaultValue={choice}
                onBlur={(e) => handleChoiceBlur(index, e.target.value)}
                margin="normal"
              />
              <IconButton onClick={() => deleteChoice(index)} color="error">
                <Delete />
              </IconButton>
            </div>
          ))}
        </div>
        <Button
          startIcon={<AddBox />}
          onClick={addNewChoice}
          variant="outlined"
          color="primary"
          style={{ marginTop: "10px" }}>
          {t("ssQuestionsModal.addChoice")}
        </Button>
      </div>
    </FormControl>
  );
};

export default Choices;
