import { FC } from "react";
import { PictoProps } from "./greeny-picto";

const FormsPicto: FC<PictoProps> = ({ width, height, fill }): JSX.Element => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 2000 2000"
      width={width}
      height={height}>
      <path
        fill={fill}
        d="M538.63,1221.5c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61c15.76,0,27.45-7.12,35.07-21.35v-93.54
		C558.97,1223.54,550.32,1221.5,538.63,1221.5z M538.63,1221.5c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61
		c15.76,0,27.45-7.12,35.07-21.35v-93.54C558.97,1223.54,550.32,1221.5,538.63,1221.5z M538.63,1221.5
		c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61c15.76,0,27.45-7.12,35.07-21.35v-93.54
		C558.97,1223.54,550.32,1221.5,538.63,1221.5z M964.58,1213.88c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22
		C999.65,1226.08,987.45,1213.88,964.58,1213.88z M1213.12,1213.88c-27.95,0-41.68,19.82-41.68,50.32l78.28-13.22
		C1248.2,1226.08,1236,1213.88,1213.12,1213.88z M1213.12,1213.88c-27.95,0-41.68,19.82-41.68,50.32l78.28-13.22
		C1248.2,1226.08,1236,1213.88,1213.12,1213.88z M964.58,1213.88c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22
		C999.65,1226.08,987.45,1213.88,964.58,1213.88z M538.63,1221.5c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61
		c15.76,0,27.45-7.12,35.07-21.35v-93.54C558.97,1223.54,550.32,1221.5,538.63,1221.5z M538.63,1221.5
		c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61c15.76,0,27.45-7.12,35.07-21.35v-93.54
		C558.97,1223.54,550.32,1221.5,538.63,1221.5z M964.58,1213.88c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22
		C999.65,1226.08,987.45,1213.88,964.58,1213.88z M1213.12,1213.88c-27.95,0-41.68,19.82-41.68,50.32l78.28-13.22
		C1248.2,1226.08,1236,1213.88,1213.12,1213.88z M1464.42,1537.87c-27.44,0-41.17,28.98-41.17,64.56
		c0,33.54,12.71,58.46,39.14,58.46c15.76,0,28.47-6.1,36.6-20.85v-83.36C1489.84,1544.99,1479.67,1537.87,1464.42,1537.87z
		 M1464.42,1537.87c-27.44,0-41.17,28.98-41.17,64.56c0,33.54,12.71,58.46,39.14,58.46c15.76,0,28.47-6.1,36.6-20.85v-83.36
		C1489.84,1544.99,1479.67,1537.87,1464.42,1537.87z M1213.12,1213.88c-27.95,0-41.68,19.82-41.68,50.32l78.28-13.22
		C1248.2,1226.08,1236,1213.88,1213.12,1213.88z M964.58,1213.88c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22
		C999.65,1226.08,987.45,1213.88,964.58,1213.88z M538.63,1221.5c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61
		c15.76,0,27.45-7.12,35.07-21.35v-93.54C558.97,1223.54,550.32,1221.5,538.63,1221.5z M538.63,1221.5
		c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61c15.76,0,27.45-7.12,35.07-21.35v-93.54
		C558.97,1223.54,550.32,1221.5,538.63,1221.5z M964.58,1213.88c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22
		C999.65,1226.08,987.45,1213.88,964.58,1213.88z M966.81,1530.24c-27.95,0-41.67,19.82-41.67,50.33l78.28-13.22
		C1001.89,1542.45,989.69,1530.24,966.81,1530.24z M1213.12,1213.88c-27.95,0-41.68,19.82-41.68,50.32l78.28-13.22
		C1248.2,1226.08,1236,1213.88,1213.12,1213.88z M1215.36,1530.24c-27.95,0-41.68,19.82-41.68,50.33l78.29-13.22
		C1250.44,1542.45,1238.24,1530.24,1215.36,1530.24z M1464.42,1537.87c-27.44,0-41.17,28.98-41.17,64.56
		c0,33.54,12.71,58.46,39.14,58.46c15.76,0,28.47-6.1,36.6-20.85v-83.36C1489.84,1544.99,1479.67,1537.87,1464.42,1537.87z
		 M1464.42,1537.87c-27.44,0-41.17,28.98-41.17,64.56c0,33.54,12.71,58.46,39.14,58.46c15.76,0,28.47-6.1,36.6-20.85v-83.36
		C1489.84,1544.99,1479.67,1537.87,1464.42,1537.87z M1215.36,1530.24c-27.95,0-41.68,19.82-41.68,50.33l78.29-13.22
		C1250.44,1542.45,1238.24,1530.24,1215.36,1530.24z M1213.12,1213.88c-27.95,0-41.68,19.82-41.68,50.32l78.28-13.22
		C1248.2,1226.08,1236,1213.88,1213.12,1213.88z M966.81,1530.24c-27.95,0-41.67,19.82-41.67,50.33l78.28-13.22
		C1001.89,1542.45,989.69,1530.24,966.81,1530.24z M964.58,1213.88c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22
		C999.65,1226.08,987.45,1213.88,964.58,1213.88z M538.63,1221.5c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61
		c15.76,0,27.45-7.12,35.07-21.35v-93.54C558.97,1223.54,550.32,1221.5,538.63,1221.5z M1755.96,719.54
		c0.2-66.16-21.7-119.7-68.58-166.08c-144.78-143.2-288.7-287.27-432.11-431.84c-44.89-45.27-97.02-66.78-160.94-66.46
		C878.89,56.23,663.45,55.51,448,55.59c-125.36,0.05-203.87,78.88-203.92,204.78c-0.08,245.94-0.02,491.88-0.02,737.82
		c0,246.93-0.05,493.85,0.02,740.78c0.03,127.64,78.22,205.83,205.87,205.84c365.96,0.05,731.92,0.05,1097.87,0
		c129.91-0.01,207.6-77.47,207.62-207.09C1755.51,1398.33,1754.9,1058.93,1755.96,719.54z M1352.9,1164.57l67.09-2.03v37.61
		c13.73-23.89,41.18-40.66,75.74-40.66c46.76,0,78.79,25.41,78.79,93.53v148.93h-76.24V1252c0-21.35-12.72-30.5-30.5-30.5
		c-16.27,0-32.03,11.18-38.63,28.47v151.98h-76.24V1164.57z M978.95,294.88c24.29-16.01,45.67-32.85,63.96-48.86
		c0.92-0.81,1.79-1.63,2.71-2.38c3.95-3.46,7.79-7.09,11.68-10.66c40.75-38.04,62.61-68.82,62.61-68.82
		c0.32,0.7,0.59,1.41,0.86,2.11c50.6,119.21,77.28,220.13,78.96,309.09c0.11,4.6,0.11,9.14,0.05,13.69
		c-0.76,79.76-22.3,149.95-65.43,215.58C1021.65,876,759.52,852.85,759.52,852.85c-14.77,27.21-50.76,48.86-74.24,60.71
		c-11.64,5.85-20.24,9.31-21.65,9.85c-0.11,0.05-0.16,0.05-0.16,0.05V820.81c1.24-1.08,2.65-2.44,4.06-4.06
		c1.62-1.79,3.3-3.79,4.98-6.01c0.05-0.05,0.05-0.05,0.05-0.05c3.84-5.03,7.79-10.76,11.04-15.58c0,0,0-0.05,0.05-0.11
		c4.17-6.28,7.09-11.09,7.09-11.09C554.86,496.67,888.36,354.63,978.95,294.88z M829.88,1159.99c0,1.02-7.12,69.64-7.12,70.66
		c-3.05,0-6.09-0.51-9.14-0.51c-21.86,0-44.74,8.13-55.92,23.89v147.92h-76.25v-237.38l67.1-2.03v37.61
		C759.73,1181.34,788.21,1159.99,829.88,1159.99z M423.75,1452.79l50.83-31.01c11.18,18.31,29.99,29.49,50.32,29.49
		c28.47,0,43.21-16.78,43.21-44.74v-25.93c-10.67,15.76-28.97,25.93-54.39,25.93c-58.96,0-98.1-46.25-98.1-122.5
		c0-82.86,57.95-124.53,124.54-124.53c49.81,0,79.81,14.23,104.21,31.01v212.48c0,69.64-43.21,107.76-108.27,107.76
		C493.39,1510.73,451.2,1496.5,423.75,1452.79z M770.12,1723.41c-5.09-11.19-6.1-20.34-8.14-32.03
		c-13.22,17.8-34.06,31.52-68.11,31.52c-59.47,0-99.12-47.78-99.12-121.99c0-72.69,42.7-125.04,102.68-125.04
		c26.43,0,40.15,7.12,50.83,16.77v-67.51h76.24v216.44c0,19.32,4.07,61.51,9.15,76.25L770.12,1723.41z M973.22,1344.51
		c20.84,0,35.07-8.14,43.71-25.93l55.41,28.47c-16.77,35.07-46.76,59.47-101.15,59.47c-72.18,0-125.04-41.68-125.04-123.52
		c0-75.23,48.29-123.52,120.47-123.52c79.3,0,113.36,54.89,113.86,130.63l-156.56,20.84
		C928.49,1335.87,949.32,1344.51,973.22,1344.51z M975.46,1660.89c20.84,0,35.07-8.14,43.71-25.93l55.41,28.47
		c-16.77,35.07-46.76,59.47-101.15,59.47c-72.18,0-125.04-41.68-125.04-123.52c0-75.23,48.29-123.52,120.47-123.52
		c79.29,0,113.35,54.89,113.86,130.63l-156.56,20.84C930.72,1652.24,951.56,1660.89,975.46,1660.89z M1221.76,1344.51
		c20.85,0,35.08-8.14,43.72-25.93l55.41,28.47c-16.78,35.07-46.77,59.47-101.15,59.47c-72.19,0-125.04-41.68-125.04-123.52
		c0-75.23,48.29-123.52,120.47-123.52c79.29,0,113.35,54.89,113.86,130.63l-156.56,20.84
		C1177.04,1335.87,1197.88,1344.51,1221.76,1344.51z M1224,1660.89c20.84,0,35.07-8.14,43.71-25.93l55.41,28.47
		c-16.77,35.07-46.76,59.47-101.15,59.47c-72.18,0-125.04-41.68-125.04-123.52c0-75.23,48.3-123.52,120.48-123.52
		c79.29,0,113.35,54.89,113.86,130.63l-156.56,20.84C1179.28,1652.24,1200.11,1660.89,1224,1660.89z M1179.06,894.57
		c-1.57,1.08-3.19,2.11-4.76,3.13l-0.05,0.05c-44.05,28.3-93.51,40.21-141.89,42.59c-2.48,0.16-5.02,0.27-7.52,0.33
		c-0.54,0.05-1.08,0.05-1.63,0.05c-1.56,0.05-3.08,0.1-4.65,0.1c-8.44,0.16-17.1,0-25.82-0.43c-2.21-0.11-4.43-0.22-6.65-0.37
		c-2.22-0.16-4.44-0.33-6.71-0.49c-22.08-1.67-43.39-4.87-63.15-8.93c-0.32-0.05-0.7-0.11-1.08-0.22
		c-70.78-14.5-122.18-38.96-122.18-38.96c-0.7,0.54-1.41,1.09-2.11,1.63c-0.6-0.22-1.14-0.48-1.74-0.7
		c-8.82-3.68-16.07-3.57-24.03,2.44c-10.66,8-22.78,12.87-36.91,12.93c0.43-1.62,0.43-2.38,0.81-2.87
		c14.83-11.74,28.08-31.76,37.34-36.47c5.2-2.65,39.72-0.54,60.99-1.41c110.39-4.49,213.64-33.33,291.73-117.53
		c55.68-60.01,85.29-133.34,93.73-214.83c1.41-13.9,2.97-27.81,3.67-41.78c0.38-7.85,4.66-9.52,11.15-11.36
		c35.44-10.11,70.13-22.45,103.08-38.91c3.46-1.73,6.17-2.65,8.22-2.87C1353.03,668.26,1307.58,807.5,1179.06,894.57z
		 M1520.84,1723.41c-5.09-11.19-6.1-20.34-8.14-32.03c-13.21,17.8-34.06,31.52-68.11,31.52c-59.47,0-99.12-47.78-99.12-121.99
		c0-72.69,42.7-125.04,102.68-125.04c26.43,0,40.16,7.12,50.83,16.77v-67.51h76.24v216.44c0,19.32,4.07,61.51,9.15,76.25
		L1520.84,1723.41z M1464.42,1537.87c-27.44,0-41.17,28.98-41.17,64.56c0,33.54,12.71,58.46,39.14,58.46
		c15.76,0,28.47-6.1,36.6-20.85v-83.36C1489.84,1544.99,1479.67,1537.87,1464.42,1537.87z M1215.36,1530.24
		c-27.95,0-41.68,19.82-41.68,50.33l78.29-13.22C1250.44,1542.45,1238.24,1530.24,1215.36,1530.24z M1213.12,1213.88
		c-27.95,0-41.68,19.82-41.68,50.32l78.28-13.22C1248.2,1226.08,1236,1213.88,1213.12,1213.88z M966.81,1530.24
		c-27.95,0-41.67,19.82-41.67,50.33l78.28-13.22C1001.89,1542.45,989.69,1530.24,966.81,1530.24z M964.58,1213.88
		c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22C999.65,1226.08,987.45,1213.88,964.58,1213.88z M713.69,1537.87
		c-27.45,0-41.17,28.98-41.17,64.56c0,33.54,12.71,58.46,39.14,58.46c15.76,0,28.47-6.1,36.6-20.85v-83.36
		C739.1,1544.99,728.94,1537.87,713.69,1537.87z M493.39,1283.52c0,35.58,13.72,61,39.65,61c15.76,0,27.45-7.12,35.07-21.35v-93.54
		c-9.14-6.09-17.79-8.13-29.48-8.13C512.19,1221.5,493.39,1242.85,493.39,1283.52z M538.63,1221.5c-26.44,0-45.24,21.35-45.24,62.02
		c0,35.58,13.72,61,39.65,61c15.76,0,27.45-7.12,35.07-21.35v-93.54C558.97,1223.54,550.32,1221.5,538.63,1221.5z M964.58,1213.88
		c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22C999.65,1226.08,987.45,1213.88,964.58,1213.88z M1213.12,1213.88
		c-27.95,0-41.68,19.82-41.68,50.32l78.28-13.22C1248.2,1226.08,1236,1213.88,1213.12,1213.88z M1215.36,1530.24
		c-27.95,0-41.68,19.82-41.68,50.33l78.29-13.22C1250.44,1542.45,1238.24,1530.24,1215.36,1530.24z M1464.42,1537.87
		c-27.44,0-41.17,28.98-41.17,64.56c0,33.54,12.71,58.46,39.14,58.46c15.76,0,28.47-6.1,36.6-20.85v-83.36
		C1489.84,1544.99,1479.67,1537.87,1464.42,1537.87z M1464.42,1537.87c-27.44,0-41.17,28.98-41.17,64.56
		c0,33.54,12.71,58.46,39.14,58.46c15.76,0,28.47-6.1,36.6-20.85v-83.36C1489.84,1544.99,1479.67,1537.87,1464.42,1537.87z
		 M1215.36,1530.24c-27.95,0-41.68,19.82-41.68,50.33l78.29-13.22C1250.44,1542.45,1238.24,1530.24,1215.36,1530.24z
		 M1213.12,1213.88c-27.95,0-41.68,19.82-41.68,50.32l78.28-13.22C1248.2,1226.08,1236,1213.88,1213.12,1213.88z M964.58,1213.88
		c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22C999.65,1226.08,987.45,1213.88,964.58,1213.88z M538.63,1221.5
		c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61c15.76,0,27.45-7.12,35.07-21.35v-93.54
		C558.97,1223.54,550.32,1221.5,538.63,1221.5z M538.63,1221.5c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61
		c15.76,0,27.45-7.12,35.07-21.35v-93.54C558.97,1223.54,550.32,1221.5,538.63,1221.5z M964.58,1213.88
		c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22C999.65,1226.08,987.45,1213.88,964.58,1213.88z M1213.12,1213.88
		c-27.95,0-41.68,19.82-41.68,50.32l78.28-13.22C1248.2,1226.08,1236,1213.88,1213.12,1213.88z M1213.12,1213.88
		c-27.95,0-41.68,19.82-41.68,50.32l78.28-13.22C1248.2,1226.08,1236,1213.88,1213.12,1213.88z M964.58,1213.88
		c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22C999.65,1226.08,987.45,1213.88,964.58,1213.88z M538.63,1221.5
		c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61c15.76,0,27.45-7.12,35.07-21.35v-93.54
		C558.97,1223.54,550.32,1221.5,538.63,1221.5z M538.63,1221.5c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61
		c15.76,0,27.45-7.12,35.07-21.35v-93.54C558.97,1223.54,550.32,1221.5,538.63,1221.5z M964.58,1213.88
		c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22C999.65,1226.08,987.45,1213.88,964.58,1213.88z M964.58,1213.88
		c-27.96,0-41.68,19.82-41.68,50.32l78.28-13.22C999.65,1226.08,987.45,1213.88,964.58,1213.88z M538.63,1221.5
		c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61c15.76,0,27.45-7.12,35.07-21.35v-93.54
		C558.97,1223.54,550.32,1221.5,538.63,1221.5z M538.63,1221.5c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61
		c15.76,0,27.45-7.12,35.07-21.35v-93.54C558.97,1223.54,550.32,1221.5,538.63,1221.5z M538.63,1221.5
		c-26.44,0-45.24,21.35-45.24,62.02c0,35.58,13.72,61,39.65,61c15.76,0,27.45-7.12,35.07-21.35v-93.54
		C558.97,1223.54,550.32,1221.5,538.63,1221.5z"
      />
    </svg>
  );
};

export default FormsPicto;
